import React, { useCallback, useMemo } from "react"
import { Link } from "react-router-dom"
import { useSamplesQuery } from "api/getters"
import dayjs from "dayjs"
import utc from "dayjs/plugin/utc"
import TableContainer from "./TableContainer"
import { useTableQueryStore } from "stores/zustand/tableQueryStore"
dayjs.extend(utc)

export function SamplesTable({ customSelect = {}, permission, pageSize: pageSizeProp, ...rest }) {
  const { page, pageSize, selector, sort } = useTableQueryStore()

  const queryVariables = {
    sort,
    page,
    selector: {
      ...selector,
      ...customSelect,
    },
    pageSize: pageSizeProp || pageSize,
  }

  const {
    data: samplesData,
    isLoading,
    isFetching,
    refetch,
  } = useSamplesQuery({
    variables: queryVariables,
  })

  const columns = useMemo(
    () => [
      {
        Header: "Sample ID",
        accessor: "id",
        hasSort: true,
        csvAccessor: "csvId",
      },
      {
        Header: "Patient ID",
        accessor: "subjectId",
        hasSort: true,
        csvAccessor: "csvSubjectId",
      },
      {
        Header: "Collected",
        accessor: "sampleCollectionDateTime",
        hasSort: true,
      },
      {
        Header: "Accessioned",
        accessor: "laboratoryReceivedDateTime",
        hasSort: true,
      },
      {
        Header: "Tube Type",
        accessor: "tubeType",
        hasSort: true,
      },
      {
        Header: "Collection Method",
        accessor: "collectionMethod",
        hasSort: true,
      },
      {
        Header: "Location",
        accessor: "sampleLocation",
        hasSort: true,
      },
      {
        Header: "Action",
        accessor: "action",
        skipCsv: true,
      },
    ],
    [],
  )

    const isValidISO = (dateStr) => {
        const isoRegex = /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2})(\.\d{3})?Z$/;
        return isoRegex.test(dateStr);
    };

    const splitDate = dateStr => {
        const datePart = dateStr.split('T')[0];
        const [year, month, day] = datePart.split('-');
        return `${month}/${day}/${year}`;
    }

  const getProcessedTableData = useCallback(
    (_samplesData) =>
      (_samplesData &&
        _samplesData.map((item) => ({
          id: item.id,
          csvId: item.id,
          subjectId: (
            <Link to={`/subject-info/${item.subjectId}/personal`} className="text-secondary" target="_blank">
              {item.subjectId}
            </Link>
          ),
          tubeType: item.tubeType,
          csvSubjectId: item.subjectId,
          sampleCollectionDateTime: isValidISO(item?.sampleCollectionDateTime) ? splitDate(item?.sampleCollectionDateTime) : null,
          laboratoryReceivedDateTime: item?.laboratoryReceivedDateTime && dayjs(item?.laboratoryReceivedDateTime).format("MM/DD/YYYY"),
          collectionMethod: item?.collectionMethod,
          status: item?.status,
          sampleLocation: item?.sampleLocation ? item?.sampleLocation : "unset",
          action: permission === "administrator" && (
            <div className="d-flex gap-2">
              <Link to={`/sample-view/${item.id}`} className="btn btn-outline-primary">
                View
              </Link>
            </div>
          ),
        }))) ||
      [],
    [],
  )

  const data = useMemo(() => getProcessedTableData(samplesData), [samplesData, getProcessedTableData])

  const filters = useMemo(() => {
    return [
      {
        field: "_id",
        operator: "$eq",
        label: "Search Sample ID",
        placeholder: "Type in Sample ID",
        removeSpaces: true,
      },
      {
        field: "subjectId",
        operator: "$eq",
        label: "Search Patient ID",
        placeholder: "Type in Patient ID",
        removeSpaces: true,
      },
      // {
      //   field: "laboratoryReceivedDateTime",
      //   operator: "$gte",
      //   label: "Accession Date",
      //   type: "date",
      // },
    ]
  }, [])

  return (
    <div>
      <TableContainer
        refetch={refetch}
        columns={columns}
        data={data}
        downloadFileName="Samples"
        isLoading={isLoading || isFetching}
        filters={filters}
        selector={selector}
        sort={sort}
        defaultSort="sampleCollectionDateTime"
        defaultSortDir="desc"
        csvQuery={useSamplesQuery}
        queryVariables={queryVariables}
        getProcessedTableData={getProcessedTableData}
        {...rest}
      />
    </div>
  )
}
