import React, { useEffect, useRef, useState } from "react"
import { Line } from "react-chartjs-2"
import moment from "moment"
import ReactLoading from "react-loading"
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from "chart.js"
import { Col, Row } from "reactstrap"

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend)

export const plugins = [
  {
    id: "backgrounds",
    beforeDraw: (chart, args, options) => {
      const {
        ctx,
        chartArea,
        scales: { y },
      } = chart

      options.hbars.forEach((hBar) => {
        ctx.save()
        ctx.fillStyle = hBar.color
        ctx.fillRect(
          chartArea.left,
          y.getPixelForValue(hBar.from),
          chartArea.right - chartArea.left,
          y.getPixelForValue(hBar.to) - y.getPixelForValue(hBar.from),
        )
        ctx.restore()
      })
    },
  },
]

const TestResultsGraph = ({ data, testId }) => {
  const records = (data?.tests?.netestRecords || data?.tests?.netest)?.all || []
  const newCancer = records.find((cancer) => cancer.id === testId)

  const [netScoreChartData, setNetScoreChartData] = useState(null)
  const [riskScoreChartData, setRiskScoreChartData] = useState(null)

  const [riskScoreOptionsData, setRiskScoreOptionsData] = useState(null)
  const [netScoreOptionsData, setNetScoreOptionsData] = useState(null)

  const scoreCategoryTableRef = useRef()
  const progressionScoreCategoryTableRef = useRef()

  useEffect(() => {
    if (newCancer) {
      const currentTest = newCancer
      const currentTestIndex = records?.findIndex((test) => test.id == newCancer.id)
      const testsBeforeCurrentTest = records?.slice(0, currentTestIndex + 1)

      if (currentTest && currentTest.testResult) {
        setNetScoreChart(testsBeforeCurrentTest)
        setRiskScoreChart(testsBeforeCurrentTest)
        setNetScoreOptions(currentTest)
        setRiskScoreOptions(currentTest)
      }
    }
  }, [data, newCancer])

  const splitDate = dateStr => {
    const datePart = dateStr.split('T')[0];
    const [year, month, day] = datePart.split('-');
    return `${month}/${day}/${year}`;
  }

  const setNetScoreChart = (tests) => {
    const sortedTests = tests
      .filter(
        (test) => getSafe(() => test["sampleCollectionDateTime"], null) !== null, // Ensure data exists
      )
      .sort((a, b) => {
        const dateA = moment(getSafe(() => a["sampleCollectionDateTime"], ""))
        const dateB = moment(getSafe(() => b["sampleCollectionDateTime"], ""))
        return dateA.diff(dateB)
      })

    let dataPoints = sortedTests.map((item) => getSafe(() => item.testResult["scoreDisplayValue"], null)).filter((item) => item !== null && item !== undefined)

    let dataLabels = sortedTests
      .map((item) => {
        const scoreDisplayValue = getSafe(() => item.testResult["scoreDisplayValue"], null)
        if (scoreDisplayValue) {
          return moment(getSafe(() => splitDate(item["sampleCollectionDateTime"]), "")).format("MM/DD/yyyy")
        }
        return null
      })
      .filter((item) => item !== null && item !== undefined)

    const data = {
      labels: ["", ...dataLabels, ""],
      datasets: [
        {
          // label: '# of Votes',
          fill: false,
          backgroundColor: "black",
          borderColor: "black",
          borderWidth: "0.95",
          pointRadius: 6,
          pointHoverRadius: 6,
          data: [NaN, ...dataPoints, NaN],
        },
      ],
    }

    setNetScoreChartData(data)
  }

  const setRiskScoreChart = (tests) => {
    const sortedTests = tests
      .filter(
        (test) => getSafe(() => test["sampleCollectionDateTime"], null) !== null, // Ensure data exists
      )
      .sort((a, b) => {
        const dateA = moment(getSafe(() => a["sampleCollectionDateTime"], ""))
        const dateB = moment(getSafe(() => b["sampleCollectionDateTime"], ""))
        return dateA.diff(dateB)
      })

    let dataPoints = sortedTests
      .map((item) => getSafe(() => item.testResult["progressionScoreDisplayValue"], null))
      .filter((item) => item !== null && item !== undefined)

    let dataLabels = sortedTests
      .map((item) => {
        const progressionScoreDisplayValue = getSafe(() => item.testResult["progressionScoreDisplayValue"], null)
        if (progressionScoreDisplayValue) {
          return moment(getSafe(() => splitDate(item["sampleCollectionDateTime"]), "")).format("MM/DD/yyyy")
        }
        return null
      })
      .filter((item) => item !== null && item !== undefined)

    const data = {
      labels: ["", ...dataLabels, ""],
      datasets: [
        {
          // label: '# of Votes',
          fill: false,
          backgroundColor: "black",
          borderColor: "black",
          borderWidth: "0.95",
          pointRadius: 6,
          pointHoverRadius: 6,
          data: [NaN, ...dataPoints, NaN],
        },
      ],
    }

    setRiskScoreChartData(data)
  }

  const setNetScoreOptions = (test) => {
    const scoreCategory = test.testConfig.scoreCategory
    const colors = ["#b3c6e9", "#ebb2b2"]
    const stepSize = scoreCategory[0].end - scoreCategory[0].start

    const hbars = scoreCategory.map((x, index) => {
      return {
        from: x.start,
        to: x.end,
        color: x.color || colors[index],
      }
    })

    const data = {
      plugins: {
        backgrounds: {
          hbars: hbars,
        },
        legend: {
          display: false,
        },
      },
      scales: {
        x: {
          grid: {
            display: false,
          },
          ticks: {
            maxRotation: 45,
            minRotation: 45,
          },
        },
        y: {
          grid: {
            display: false,
          },
          max: 100,
          min: 0,
          beginAtZero: true,
          ticks: {
            stepSize: stepSize,
          },
          title: {
            display: true,
            text: "NET SCORE",
            font: {
              size: 15,
            },
          },
        },
      },
      responsive: true,
    }

    setNetScoreOptionsData(data)
  }

  const setRiskScoreOptions = (test) => {
    const progressionScoreCategory = test.testConfig.progressionScoreCategory
    const colors = ["#b2e8b4", "#b3c6e9", "#ebc9b2", "#ebb2b2"]

    const stepSize = progressionScoreCategory[0].end - progressionScoreCategory[0].start

    const hbars = progressionScoreCategory.map((x, index) => {
      return {
        from: x.start,
        to: x.end,
        color: x.color || colors[index],
      }
    })

    const data = {
      plugins: {
        backgrounds: {
          hbars: hbars,
        },
        legend: {
          display: false,
        },
      },
      scales: {
        x: {
          grid: {
            display: false,
          },
          ticks: {
            maxRotation: 45,
            minRotation: 45,
          },
        },
        y: {
          grid: {
            display: false,
          },
          max: 100,
          min: 0,
          beginAtZero: true,
          ticks: {
            stepSize: stepSize,
          },
          title: {
            display: true,
            text: "PROGRESSION RISK SCORE",
            font: {
              size: 15,
            },
          },
        },
      },
      responsive: true,
      // maintainAspectRatio: false
    }

    setRiskScoreOptionsData(data)
  }

  const getSafe = (fn, defaultVal) => {
    try {
      return fn()
    } catch (e) {
      return defaultVal
    }
  }

  return (
    <div>
      {!data || !newCancer || !netScoreOptionsData || !riskScoreOptionsData ? (
        <ReactLoading type="spin" color="red" height={40} width={40} />
      ) : (
        <div style={{ maxHeight: "600px", overflowY: "auto" }}>
          <Row style={{ marginTop: "30px" }}>
            <Col lg="6">
              <div ref={scoreCategoryTableRef}>
                <h3 className="text-center">
                  NET SCORE: <span className="text-danger">{newCancer.testResult.scoreCategory}</span>
                </h3>
                <Line options={netScoreOptionsData} data={netScoreChartData} plugins={plugins} />
              </div>
            </Col>
            {newCancer.testResult.scoreCategory && newCancer.testResult.scoreCategory !== "NEGATIVE" && (
              <Col lg="6">
                <div ref={progressionScoreCategoryTableRef}>
                  <h3 className="text-center">
                    PROGRESSION SCORE: <span className="text-danger">{newCancer.testResult.progressionScoreCategory}</span>
                  </h3>
                  <Line options={riskScoreOptionsData} data={riskScoreChartData} plugins={plugins} />
                </div>
              </Col>
            )}
          </Row>

          {newCancer.testResult.scoreCategory && newCancer.testResult.scoreDisplayValue && (
            <center>
              <div className="mt-3 mb-3" style={{ paddingTop: "15px", paddingBottom: "10px" }}>
                <h5>
                  The NETest score is <b>{newCancer.testResult.scoreDisplayValue}</b>, it is <b>{newCancer.testResult.scoreCategory}.</b>
                </h5>
                {newCancer.testResult.progressionScoreCategory &&
                  newCancer.testResult.progressionScoreDisplayValue &&
                  newCancer.testResult.scoreCategory !== "NEGATIVE" && (
                    <h5>
                      The Progression score is <b>{newCancer.testResult.progressionScoreDisplayValue}</b>, it is{" "}
                      <b>{newCancer.testResult.progressionScoreCategory}.</b>
                    </h5>
                  )}
              </div>
            </center>
          )}
        </div>
      )}
    </div>
  )
}

export default TestResultsGraph
