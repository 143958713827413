import React, { useCallback, useEffect, useMemo, useState } from "react"
import { usePatientDetailsTestsQuery, useTestDetailsQuery } from "api/getters"
import { Alert, Button, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row, Table, Col, Spinner, FormFeedback } from "reactstrap"
import localizedFormat from "dayjs/plugin/localizedFormat"
import dayjs from "dayjs"
import TestResultsGraph from "./TestResultsGraph"
import utc from "dayjs/plugin/utc"
import * as Yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"
import { useForm } from "react-hook-form"
import { Link } from "react-router-dom"
import { useUpdateTestDates } from "api/mutators"
import { makeApiDownloadCall } from "api/generic-api"
dayjs.extend(utc)
dayjs.extend(localizedFormat)

const schema = Yup.object({
  sampleCollectionDateTime: Yup.date()
    .transform((value, originalValue) => (originalValue === "" ? null : value))
    .max(new Date(), "The date must be in the past")
    .required("Please enter collection date"),
  laboratoryReceivedDateTime: Yup.date().when(["sampleCollectionDateTime"], ([sampleCollectionDateTime]) => {
    if (sampleCollectionDateTime) {
      return Yup.date()
        .transform((value, originalValue) => (originalValue === "" ? null : value))
        .min(new Date(sampleCollectionDateTime), "The date must be after collection date")
        .max(new Date(), "The date must be in the past")
        .nullable()
    }

    return Yup.date()
      .transform((value, originalValue) => (originalValue === "" ? null : value))
      .nullable()
      .notRequired()
      .max(new Date(), "The date must be in the past")
  }),
  laboratoryCompletedDateTime: Yup.date().when(["laboratoryReceivedDateTime"], ([laboratoryReceivedDateTime]) => {
    if (laboratoryReceivedDateTime) {
      return Yup.date()
        .transform((value, originalValue) => (originalValue === "" ? null : value))
        .min(new Date(laboratoryReceivedDateTime), "The date must be after accessioned date")
        .max(new Date(), "The date must be in the past")
        .nullable()
    }

    return Yup.date()
      .transform((value, originalValue) => (originalValue === "" ? null : value))
      .nullable()
      .notRequired()
      .max(new Date(), "The date must be in the past")
  }),
})

const TestDetails = ({ testId, permission }) => {
  const { data: testDetailsData, isLoading } = useTestDetailsQuery({
    variables: {
      id: testId,
    },
  })

  const [editing, setEditing] = useState(false)
  const [successMessage, setSuccessMessage] = useState("")
  const [patientId, setPatientId] = useState("")
  const [downloadError, setDownloadError] = useState("")
  const [modal, setModal] = useState(false)
  const [graphModal, setGraphModal] = useState(false)
  const toggle = () => setModal(!modal)
  const toggleGraphModal = () => setGraphModal(!graphModal)
  const editable = testDetailsData?.testType === "netest" || testDetailsData?.testType === "prostest"

  const { data: patientsData } = usePatientDetailsTestsQuery({ variables: { id: patientId }, enabled: Boolean(patientId) })

  const results = useMemo(
    () =>
      typeof testDetailsData?.testResult === "object"
        ? Object.keys(testDetailsData?.testResult || {})?.map((item) => ({
            result: item,
            value: testDetailsData?.testResult[item],
          }))
        : testDetailsData?.testResult,
    [testDetailsData?.testResult],
  )

  const [downloading, setDownloading] = useState(false)

  const downloadReport = async () => {
    try {
      setDownloadError("")
      setDownloading(true)
      const response = await makeApiDownloadCall("get", "/api/document/test/:id.pdf", { id: testId }, "application/pdf", `${testId}.pdf`)
      if (!response?.ok) {
        setDownloadError("PDF not yet available")
      }
    } catch (error) {
      console.error(error)
    } finally {
      setDownloading(false)
    }
  }

  const [defaultValues, setDefaultValues] = useState({ sampleCollectionDateTime: "", laboratoryReceivedDateTime: "", laboratoryCompletedDateTime: "" })
  const {
    reset,
    register,
    handleSubmit,
    getValues,
    watch,
    formState: { errors, isDirty },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues,
  })

  const watchLaboratoryCompletedDateTime = watch("laboratoryCompletedDateTime")

  const {
    mutate,
    isLoading: mutating,
    isError,
    error,
  } = useUpdateTestDates({
    variables: {
      testId,
    },
    onSuccess: (successData) => {
      setEditing(false)
      setSuccessMessage(successData.message)
      setDefaultValues(getValues())
      reset(getValues())
    },
  })

  const isValidISO = (dateStr) => {
    const isoRegex = /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2})(\.\d{3})?Z$/;
    return isoRegex.test(dateStr);
  };

  const splitDate = dateStr => {
    const datePart = dateStr.split('T')[0];
    const [year, month, day] = datePart.split('-');
    return `${year}-${month}-${day}`;
  }


  useEffect(() => {
    setPatientId(testDetailsData?.subjectId)
  }, [testDetailsData?.subjectId])

  useEffect(() => {
    if (testDetailsData) {
      const newDefault = {
        sampleCollectionDateTime: isValidISO(testDetailsData.sampleCollectionDateTime) ? splitDate(testDetailsData.sampleCollectionDateTime) : "",
        laboratoryReceivedDateTime: testDetailsData.laboratoryReceivedDateTime ? dayjs(testDetailsData.laboratoryReceivedDateTime).format("YYYY-MM-DD") : "",
        laboratoryCompletedDateTime: testDetailsData.laboratoryCompletedDateTime
          ? dayjs(testDetailsData.laboratoryCompletedDateTime).format("YYYY-MM-DDTHH:mm")
          : "",
      }
      reset(newDefault)
      setDefaultValues(newDefault)
    }
  }, [testDetailsData, reset])

  const onSubmit = handleSubmit(async (data) => {
    setSuccessMessage("")

    const formValues = {
      sampleCollectionDateTime: data.sampleCollectionDateTime ? dayjs(data.sampleCollectionDateTime) : null,
      laboratoryReceivedDateTime: data.laboratoryReceivedDateTime ? dayjs(data.laboratoryReceivedDateTime).format("YYYY-MM-DD") : null,
      laboratoryCompletedDateTime: data.laboratoryCompletedDateTime ? dayjs(data.laboratoryCompletedDateTime).utc().format() : null,
    }

    try {
      await mutate(formValues)
    } catch (err) {
      console.error({ err })
    }
  })

  const convertToTimezone = (dateTimeValue) => {
    if (!dateTimeValue) return

    const date = new Date(dateTimeValue)
    const timezone = "America/New_York" // Change to the desired timezone
    const options = {
      timeZone: timezone,
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: false,
    }

    const converted = new Intl.DateTimeFormat("en-US", options).format(date)
    return converted
  }

  return (
    <form onSubmit={onSubmit}>
      {downloadError && (
        <Alert color="warning" role="alert">
          {downloadError}
        </Alert>
      )}
      {successMessage ? <Alert color="success">{successMessage}</Alert> : null}
      {isError && (
        <Alert color="warning" role="alert">
          {error?.response?.data?.message || error?.response?.data?.reason}
        </Alert>
      )}
      {isLoading ? (
        <Spinner type="grow" />
      ) : (
        <Row>
          <Col className="mb-3" lg="6">
            <Label className="form-label">Test ID</Label>
            <input className="form-control" value={testDetailsData?.id} readOnly />
          </Col>
          <Col className="mb-3" lg="6">
            <Label className="form-label">Patient ID</Label>
            <Link to={`/subject-info/${testDetailsData?.subjectId}/personal`} className="text-secondary" target="_blank">
              <input className="form-control cursor-pointer" value={testDetailsData?.subjectId} readOnly />
            </Link>
          </Col>

          <Col className="mb-3" lg="6">
            <Label className="form-label">Type</Label>
            <input className="form-control" value={testDetailsData?.testTypeName || testDetailsData?.testType?.toUpperCase()} readOnly />
          </Col>
          <Col className="mb-3" lg="6">
            <Label className="form-label">Tube Type</Label>
            <input className="form-control" value={testDetailsData?.tubeType?.toUpperCase()} readOnly />
          </Col>
          {/* <Col className="mb-3" lg="6">
            <Label className="form-label">Analysed</Label>
            <input className="form-control" value={dayjs(testDetailsData?.laboratoryCompletedDateTime).utc().format("YYYY/MM/DD, hh:mm A")} readOnly />
          </Col> */}
          <Col className="mb-3" lg="6">
            <Label className="form-label">Collected</Label>
            <input
              className={`form-control ${Boolean(errors?.sampleCollectionDateTime?.message) ? "is-invalid" : ""}`}
              type="date"
              disabled={!editing}
              {...register("sampleCollectionDateTime")}
            />
            {Boolean(errors?.sampleCollectionDateTime?.message) && <FormFeedback type="invalid">{errors.sampleCollectionDateTime.message}</FormFeedback>}
          </Col>
          <Col className="mb-3" lg="6">
            <Label className="form-label">Accessioned</Label>
            <input
              className={`form-control ${Boolean(errors?.laboratoryReceivedDateTime?.message) ? "is-invalid" : ""}`}
              type="date"
              disabled={!editing}
              {...register("laboratoryReceivedDateTime")}
            />
            {Boolean(errors?.laboratoryReceivedDateTime?.message) && <FormFeedback type="invalid">{errors.laboratoryReceivedDateTime.message}</FormFeedback>}
          </Col>
          <Col className="mb-3" lg="6">
            <Label className="form-label">Reported</Label>
            <input
              className={`form-control ${Boolean(errors?.laboratoryCompletedDateTime?.message) ? "is-invalid" : ""}`}
              type="datetime-local"
              disabled={!editing}
              {...register("laboratoryCompletedDateTime")}
            />
            {watchLaboratoryCompletedDateTime && (
              <small>Timezone (America/New_York): {convertToTimezone(dayjs(watchLaboratoryCompletedDateTime).format("YYYY-MM-DDTHH:mm"))}</small>
            )}
            {Boolean(errors?.laboratoryCompletedDateTime?.message) && <FormFeedback type="invalid">{errors.laboratoryCompletedDateTime.message}</FormFeedback>}
          </Col>
          <Col className="mb-3" lg="6">
            <Label className="form-label">PCR Updated</Label>
            <input className="form-control" type="date" value={dayjs(testDetailsData?.pcrUpdatedDateTime).format("YYYY-MM-DD")} readOnly />
          </Col>
          <Col className="mb-3" lg="6">
            <Label className="form-label">Insured</Label>
            <input
              className="form-control"
              value={
                testDetailsData?.patientInsured === true || testDetailsData?.insured === true
                  ? "Yes"
                  : testDetailsData?.patientInsured === false || testDetailsData?.insured === false
                  ? "No"
                  : "No"
              }
              readOnly
            />
          </Col>
          {typeof results === "string" && (
            <Col className="mb-3" lg="6">
              <Label className="form-label card-title">Result</Label>
              <input className="form-control" value={results ? `${results}`.toUpperCase() : ""} readOnly />
            </Col>
          )}
          <div className="d-flex flex-wrap gap-3 align-items-start justify-content-between mt-4">
            {editable && permission === "administrator" && (
              <div className="d-flex gap-2">
                {editing && (
                  <Button
                    key="cancel"
                    outline
                    type="button"
                    onClick={() => {
                      setEditing(false)
                      reset(defaultValues)
                    }}
                    disabled={mutating}
                  >
                    Cancel
                  </Button>
                )}
                {editing ? (
                  <Button key="submit" color="danger" type="submit" disabled={mutating || !isDirty}>
                    {mutating && <Spinner size="sm" />} Update Test Details
                  </Button>
                ) : (
                  <Button
                    key="edit"
                    color="danger"
                    type="button"
                    onClick={() => {
                      setEditing(true)
                      setSuccessMessage("")
                    }}
                  >
                    Edit Test Details
                  </Button>
                )}
              </div>
            )}
            <div className="d-flex flex-wrap gap-2 align-items-start justify-content-start">
              <Button color="primary" disabled={downloading} onClick={downloadReport}>
                {downloading && <Spinner size="sm" />} Download Report
              </Button>

              {/* {permission === "administrator" && Array.isArray(results) && results.length > 0 && <Button onClick={toggle}>View Test Result</Button>}

              {Array.isArray(results) && results.length > 0 && (
                <Button type="button" onClick={toggleGraphModal} className="btn btn-secondary btn-block">
                  View Test Result Graph
                </Button>
              )} */}

              {permission === "administrator" && Array.isArray(results) && results.length > 0 && (
                <Link to={`/process-cancer-pcr?testId=${testDetailsData?.id}`}>
                  <Button>View Test Result</Button>
                </Link>
              )}
            </div>
          </div>
        </Row>
      )}

      <Modal isOpen={modal} toggle={toggle} className="modal-dialog-centered">
        <ModalHeader toggle={toggle}>Test Result</ModalHeader>
        <ModalBody scrollable>
          <div style={{ maxHeight: "600px", overflowY: "auto" }}>
            {Array.isArray(results) && results.length > 0 ? (
              <Table striped>
                <thead>
                  <tr>
                    <th>Gene</th>
                    <th>Value</th>
                  </tr>
                </thead>

                <tbody>
                  {results?.map((item) => (
                    <tr key={item}>
                      <td>{item.result}</td>
                      <td>{typeof item.value === "boolean" ? item.value.toString() : item.value}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            ) : (
              <p>No records found.</p>
            )}
          </div>
        </ModalBody>
        <ModalFooter className="justify-content-end">
          <Button color="secondary" onClick={toggle}>
            Close
          </Button>
        </ModalFooter>
      </Modal>

      <Modal isOpen={graphModal} toggle={toggleGraphModal} className="modal-dialog-centered" size="xl">
        <ModalHeader toggle={toggleGraphModal}>Test Result Graph</ModalHeader>
        <ModalBody scrollable>
          <TestResultsGraph data={patientsData} testId={testId} />
        </ModalBody>
        <ModalFooter className="justify-content-end">
          <Button color="secondary" onClick={toggleGraphModal}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
    </form>
  )
}

export default TestDetails
